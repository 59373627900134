import { useRoutes } from 'react-router-dom'
import NotFound from '../pages/error/not-found'
import Redirect from '../components/Redirect'

import BaseLayout from '@/layout/BaseLayout'
import { setupGlobalComponent } from './routes'

const AppRouter = () => {
  /**
   * 这里主要是根据角色生成路由，
   * 如果需要通过返回菜单列表生成路由，菜单列表需要有一个name字段去匹配
   */

  const routes = setupGlobalComponent();

  const currentPath = routes.find((item)=> {
    return `${item.path.split('/')[1]}` === `${window.location.pathname.split('/')[1]}`
  });

  let title = 'Aerohub client';
  if (currentPath?.title) {
    title = `${currentPath?.title} - Aerohub client`;
  }
  document.title = title;

  const element = useRoutes([
    // 重定向
    { path: '/', element: <Redirect to="/flight" /> },
    ...routes,
    {
      path: '*',
      element: (
        <BaseLayout>
          <NotFound />
        </BaseLayout>
      ),
    },
  ])

  return element
}

export default AppRouter
