import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const AccountPage = lazy(() => import('@/pages/account'))

const AccountRoute = [
  {
    path: '/account',
    title: 'Account',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <AccountPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
] as RouteObject[]

export default AccountRoute;
