import HttpRequest from '@/utils/request'
import {
  AddQuestionParams,
  QuestionDetailType,
  QuestionItemType,
  QuestionRequestParamsType,
  ReplyQuestionType,
  closedQuestionType,
} from './type'


export const getQuestionUnreadNum = (): Promise<WithPaginationResult<QuestionItemType[]>> => {
  return HttpRequest.get({
    url: `/client/qa/unreadnum`
  })
}

export const getQuestionList = (
  params: QuestionRequestParamsType
): Promise<WithPaginationResult<QuestionItemType[]>> => {
  return HttpRequest.get({
    url: `/client/qa/list`,
    params,
  })
}

export const addQuestion = (data: AddQuestionParams): Promise<any> => {
  return HttpRequest.post({
    url: `/client/qa/add`,
    data,
  })
}

export const getQuestionDetail = ({
  id,
}: {
  id: string
}): Promise<QuestionDetailType> => {
  return HttpRequest.get({
    url: `/client/qa/detail/${id}`,
  })
}

export const replyQuestion = (data: ReplyQuestionType): Promise<any> => {
  return HttpRequest.post({
    url: `/client/qa/add_detail`,
    data,
  })
}

export const closedQuestion = (data: closedQuestionType): Promise<any> => {
  return HttpRequest.post({
    url: `/client/qa/closed`,
    data,
  })
}
