import React, { useEffect, useRef } from 'react';
import lottie from "lottie-web";
import animationData from 'src/assets/animation/airline-loader-2.json';

export type Props = {
  loading?: boolean;
};

const FlightLoading: React.FC<Props> = ({ loading }) => {
  const animationRef = useRef(null);
  let animationInstance = null;
  useEffect(() => {
    if (loading == true) {
      animationInstance = lottie.loadAnimation({
        container: animationRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData
      });
      return () => {
        animationInstance.destroy();
      };
    }
  }, [loading]);

  return (
    <>
    {loading == true  && (
      <div className='loading'>
        <div ref={animationRef} className="animation" />
      </div>
    )}
    </>
  );
};
export default FlightLoading;
