import { css } from '@emotion/react'

export const DropdownItemStyle = css`
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
`
export const DropdownUserNameStyle = css`
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0px 8px;
  border-radius: 6px;
  :hover{
    background:#f6f7f9;
  }
`
export const DownloadsIconStyle = css`
  font-size: 16px;
  cursor: pointer;
  margin-left:10px;
  :hover{
    color:#1565C0;
  }
`
