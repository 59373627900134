import { message } from 'antd'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getToken, removeToken } from './auth'

export interface ResponseResult<T = unknown> {
  code: number
  data: T
  msg: string
}

export type RequestConfig = AxiosRequestConfig & { isDictJudgeStatus?: boolean }

class HttpRequest {
  public baseURL = process.env.REACT_APP_BASE_API
  public timeout = 5000 * 12

  public redirectUrl() {
    removeToken();
    window.location.replace('/login');
  }

  public init(options: RequestConfig) {
    const instance = axios.create()

    options = this.mergeOptions(options)

    this.setInterceptors(instance)

    return instance(options)
  }

  // 拦截器
  setInterceptors(instance: AxiosInstance) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        const token = getToken();
        if (token && config.headers) {
          // 请求头token信息，请根据实际情况进行修改
          config.headers['Authorization'] = `Bearer ${token}`;
        } else if (!token && (config.url !== '/client/user/login' && config.url !== '/client/saml/token' && config.url !== '/client/letsflyhub/fastlogin') )  {
          this.redirectUrl();
        }

        return config;
      },
      (error) => {
        this.redirectUrl();
        return Promise.reject(error);
      }
    )

    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        const { status, msg } = res.data;
        if (status === 103 || status === 403) {
          this.redirectUrl();
          return Promise.reject(res.data)
        }

        if (status !== 0 && (res.config as RequestConfig).isDictJudgeStatus) {
          message.error(msg, 3);
          return Promise.reject(res.data);
        }

        return res.data;
      },
      (error) => {
        // 取消请求
        if (error?.code === 'ERR_CANCELED') {
          return Promise.reject(error)
        }
        // 处理 错误异常提示
        // const errMsg = error?.message ?? 'System busy';
        // message.error(errMsg, 3);
        return Promise.reject(error);
      }
    )
  }

  mergeOptions(options: AxiosRequestConfig & { isDictJudgeStatus?: boolean }) {
    return Object.assign(
      {
        baseURL: this.baseURL,
        timeout: this.timeout,
        isDictJudgeStatus: true,
      },
      {
        ...options,
      }
    )
  }

  public async request<T = unknown>(
    options: AxiosRequestConfig,
    config: RequestConfig = {}
  ) {
    try {
      const res = await this.init({ ...options, ...config })
      if (options?.responseRes) {
        return (res as T);
      }
      // return res.data as ResponseResult<T> // 真实接口中，你可能会用到这个
      return (res.data as T) || (res as T)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  public get<T = any>(options: AxiosRequestConfig, config?: RequestConfig) {
    return this.request<T>(
      {
        method: 'GET',
        ...options,
      },
      config
    )
  }
  public post<T = any>(options: AxiosRequestConfig, config?: RequestConfig) {
    return this.request<T>(
      {
        method: 'POST',
        ...options,
      },
      {
        ...config,
      }
    )
  }
  public getRes<T = any>(options: AxiosRequestConfig, config?: RequestConfig) {
    Object.assign(options, {
      responseRes: true
    })
    return this.request<T>(
      {
        method: 'GET',
        ...options
      },
      config
    )
  }

  public postRes<T = any>(options: AxiosRequestConfig, config?: RequestConfig) {
    Object.assign(options, {
      responseRes: true
    })
    return this.request<T>(
      {
        method: 'POST',
        ...options,
      },
      {
        ...config,
      }
    )
  }
  public put<T = any>(options: AxiosRequestConfig, config?: RequestConfig) {
    return this.request<T>(
      {
        method: 'PUT',
        ...options,
      },
      config
    )
  }
  public patch<T = any>(options: AxiosRequestConfig, config?: RequestConfig) {
    return this.request<T>(
      {
        method: 'PATCH',
        ...options,
      },
      config
    )
  }
  public delete<T = any>(options: AxiosRequestConfig, config?: RequestConfig) {
    return this.request<T>(
      {
        method: 'DELETE',
        ...options,
      },
      config
    )
  }
}

export default new HttpRequest()
