import { FONT_COLOR } from '@/constant/style'
import styled from '@emotion/styled'

export interface StatusProps {
  options: Array<{
    value: string | number
    label: string
    color: 'yellow' | 'blue' | 'gray' | 'red' | 'green'
  }>
  value: string | number
}
const Status: React.FC<StatusProps> = ({ options, value }) => {
  const record = options.find((item) => item.value === value)

  return <Container className={`${record?.color}`}>{record?.label}</Container>
}

export default Status

const Container = styled.span`
  display: block;
  padding-left: 14px;
  position: relative;
  &::before {
    display: inline-block;
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
  }
  &.yellow {
    &::before {
      background-color: #f49800;
    }
  }
  &.blue {
    &::before {
      background-color: #35a2e5;
    }
  }
  &.gray {
    &::before {
      background-color: #bbbbbb;
    }
  }
  &.red {
    &::before {
      background-color: #ff4d4f;
    }
  }
  &.green {
    &::before {
      background-color: #58c723;
    }
  }
`
