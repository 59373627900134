import { getUserInfo, login, logOut } from '@/services/user'
import { LoginRequestParams, UserInfo } from '@/services/user/type'
import { RootState } from '@/store'
import { getToken, removeToken, setToken } from '@/utils/auth'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface UserState {
  token?: string
  userInfo: UserInfo | null
}

export const signIn = createAsyncThunk(
  'user/signIn',
  async (data: LoginRequestParams) => {
    try {
      const res = await login(data)
      return {
        token: res.token,
      }
    } catch (error) {}
    return {
      token: '',
    }
  }
)

export const signup = createAsyncThunk('user/signup', async () => {
  try {
    await logOut()
    return true
  } catch (error) {}
  return false
})

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  try {
    const userInfo = await getUserInfo()
    return userInfo
  } catch (error) {}
  return null
})

const initialState = {
  token: getToken(),
  userInfo: null,
} as UserState

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      const token = action.payload?.token
      state.token = token
      setToken(token!)
    })
    builder.addCase(signup.fulfilled, (state, action) => {
      // if (action.payload) {
      //   state.token = undefined
      //   removeToken()
      //   window.location.reload()
      // }
      state.token = undefined
      removeToken()
      window.location.reload()
    })
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userInfo = action.payload
    })
  },
})

export const selectToken = (state: RootState) => state.user.token
export const selectUserInfo = (state: RootState) => state.user.userInfo

export default userSlice.reducer
