import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const PaySuccessPage = lazy(() => import('@/pages/pay-success'))

const PayRoute = [
  {
    path: '/pay-success',
    title: 'Pay success',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <PaySuccessPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
] as RouteObject[]

export default PayRoute
