/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { ReloadOutlined ,DownloadOutlined} from '@ant-design/icons';
import { Spin, Button, Drawer, message, Table, Space,Progress} from 'antd';
import Status, { StatusProps } from '@/components/Status'
import { downloadTaskList } from '@/services/common/downloads'
import { cloneDeep } from 'lodash'
import { decodeAndDecompress, jsonToExcel, YmdHis } from '@/services/common/data'
import axios from 'axios';

export type taskProps = {
  downloadDrawer?: number;
  setDownloadDrawer?: any;
};

const DownloadsTask: React.FC<taskProps> = ({ downloadDrawer,setDownloadDrawer }) => {
  const actionRef = useRef<ActionType>();
  const [taskItems, setTaskItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;
  const openTaskDrawer = (pageNum) => {
    setLoading(true);
    downloadTaskList({
      source: 'client',
      page: pageNum,
      pageSize: pageSize,
    }).then((response) => {
      setTotal(response.total)
      setTaskItems(response.data)
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
    });
    return;
  };
  const close = () => {
    setCurrentPage(1);
    setDownloadDrawer(0);
  };
  const switchPages = pagination => {
    setCurrentPage(pagination.current);
    openTaskDrawer(pagination.current);
  };

  // 下载excel文件
  const downloadExcel = (record:any) => {
    const hide = message.loading('Downloading...', 15000);
    axios.get(record.download_url).then(response => {
        if (response.status == 200) {
          const jsonData = decodeAndDecompress(response.data ?? '');
          jsonToExcel(jsonData, record.name);
          hide();
        }
    })
    .catch(error => {
      hide();
      return message.error('Failed to generate excel');
    });

  };

  useEffect(() => {
    if (downloadDrawer >= 1) {
      setCurrentPage(1);
      openTaskDrawer(1);
    }
  }, [downloadDrawer]);


  useEffect(() => {

    // 无感刷新
    const fetchRefresh = async () => {

      //获取正在导出的数据
      if (loading == false && downloadDrawer >= 1) {
        const processingIds = [];
        taskItems.map((item,index) => {
          if (item.status == 'processing') {
            processingIds.push(item.id)
          }
        })

        if (processingIds.length > 0) {
          downloadTaskList({
            source: 'client',
            page: 1,
            ids: processingIds,
            pageSize: pageSize,
          }).then((response) => {
            const newTaskItems = [];
            let is_update = 0;
            cloneDeep(taskItems).map((item,index) => {
              let findData = response.data.find(fitem => fitem.id === item.id);
              if (findData != undefined) {
                let upItem = item;
                upItem.status = findData.status;
                upItem.progress = findData.progress;
                upItem.download_url = findData.download_url;
                is_update = 1;
                newTaskItems.push(upItem);
              }else{
                newTaskItems.push(item);
              }
            })
            if (is_update == 1) {
              setTaskItems(newTaskItems);
            }

          });
        }
      }
    };

    // 定时获取
    const intervalId = setInterval(fetchRefresh, 1000);
    return () => {
      clearInterval(intervalId);
    };
}, [taskItems]);


  const options: StatusProps['options'] = [
    { label: 'Processing', value: 'processing', color: 'blue' },
    { label: 'Completed', value: 'completed', color: 'blue' },
    { label: 'Failed', value: 'failed', color: 'red' },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => (
        <div>
          <div>{record.title}</div>
          <div>{record.time_filter}</div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      with:300,
      render: (_, record: any) => (
        <>
        {record.status == 'processing' ? (
          <Progress percent={record.progress} showInfo={false} size="small" />
        ) : (
          <Status options={options} value={record.status} /> 
        )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      align:'center',
      render: (_, record) => (
        <Space size="middle">
          {record.status == 'completed' && (
            <a onClick={()=>downloadExcel(record)}><DownloadOutlined /></a>
          )}
        </Space>
      ),
    },
  ];
  

  return (
    <>
    {downloadDrawer > 0 && (
      <Drawer  width='500' title={(
        <div className='flex-space-between'>
          <span>Downloads <DownloadOutlined className='f14' /></span>
          <span className='pointer f15' onClick={() => openTaskDrawer(currentPage)}><ReloadOutlined /></span>
        </div>
      )} open={downloadDrawer > 0} onClose={close} >
        <Table
          size="small"
          loading={loading} 
          columns={columns} 
          dataSource={taskItems}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
          }}
          onChange={switchPages}
        />
      </Drawer>
    )}
    </>
  );
};
export default DownloadsTask;
