import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const BookingsPage = lazy(() => import('@/pages/bookings'))
const BookingDetailPage = lazy(() => import('@/pages/booking-detail'))

const BookingsRoute = [
  {
    path: '/bookings',
    title: 'Bookings',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <BookingsPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
  {
    path: '/booking-detail/:orderNo',
    title: 'Booking detail',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <BookingDetailPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
] as RouteObject[]

export default BookingsRoute
