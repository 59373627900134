import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const QuestionPage = lazy(() => import('@/pages/question'))
const QuestionDetailPage = lazy(() => import('@/pages/question-detail'))

const QuestionRoute = [
  {
    path: '/question',
    title: 'Question',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <QuestionPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
  {
    path: '/question-detail/:id',
    title: 'Question detail',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <QuestionDetailPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
] as RouteObject[]

export default QuestionRoute
