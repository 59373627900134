/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import lodash from 'lodash';
import { css, jsx } from '@emotion/react'
import { Badge,Avatar,Input} from 'antd';
import { getToken } from '@/utils/auth';
import { v4 as uuid } from 'uuid'; // 从 uuid 库中导入 v4 方法
import {
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { ChatBubbleLeftEllipsisIcon ,PaperAirplaneIcon, XMarkIcon} from '@heroicons/react/24/solid'
import { sendMessageApi , messages} from '@/services/common/message'
const { TextArea } = Input;

const MessageService: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const messageContentRef = useRef(null);
  const [chatVisible, setChatVisible]= useState(false);
  const [connectionStatus, setConnectionStatus] = useState('1');
  const [messageLoading, setMessageLoading] = useState(false);
  const [messageItems, setMessageItems] = useState([]);
  const [messageValue, setMessageValue] = useState("");
  const [chatSocket, setChatSocket] = useState(null);
  const [moreLoading, setMoreLoading] = useState(false);
  const currentPageNum = useRef(1);
  const moreLoadingRef = useRef(false);
  const currentFd = useRef(0);
  let reconnectAttempts = 0;
  const createConnection = () => {
    if (chatSocket && chatSocket.readyState === WebSocket.OPEN) {
      return;
    }
    setConnectionStatus('0');
    const socket = new WebSocket(process.env.REACT_APP_CHAT_WEBSOCKET_SERVICE + '?token='+getToken());
    setChatSocket(socket)
    socket.onopen = function(event) {
        setConnectionStatus('1');
    };

    socket.onmessage = function(event) {
      const content = JSON.parse(event.data);
        switch (content.type) {
          case 'connected':
            currentFd.current = content.fd;
            break;
          case 'message':
            if (content?.fd != currentFd.current) {
              const user = content?.user == 'me' ? 'me'  : 'aerohub';
              receiveMessage(content.message, user); 
            }
            break;
      
          default:
            break;
        }
    };
    
    socket.onclose = function(event) {
      setConnectionStatus('0');
      setChatSocket(null);
      const reconnect = () => {
        const delay = Math.min(10000, 1000 * Math.pow(2, reconnectAttempts)); // Exponential backoff
        reconnectAttempts++;
        setTimeout(createConnection, delay);
      };
      
      reconnect();
    };
  }

  const openChat = () => {
    if (chatVisible == true) {
      setChatVisible(false);
    }else{
      if (chatSocket && chatSocket.readyState === WebSocket.OPEN || connectionStatus == '0') {
        setChatVisible(true);
      }else{
        setChatVisible(true);
        getMessage();
      }
    }
  }

  const getMessage = () => {
    if (moreLoadingRef.current == true){
       return
    };
    setMoreLoading(true);
    moreLoadingRef.current = true;
    messages({page:currentPageNum.current}).then((response) => {
      setMessageItems(historyItems => {
        const updatedArray = lodash.cloneDeep(historyItems);
        return [...response.data, ...updatedArray];
      });
      currentPageNum.current = currentPageNum.current+1;
      if (currentPageNum.current == 2) {
        setTimeout(() => {
          const containers = document.querySelectorAll('.message-content');
          containers.forEach(container => {
            container.scrollTop = container.scrollHeight;
          });
          createConnection();
        }, 200);
      }
      setMoreLoading(false);
      moreLoadingRef.current = false;
    }).catch((error) => {
      setMessageLoading(false);
      setMoreLoading(false);
      moreLoadingRef.current = false;
    });
  }

  const receiveMessage = (messageValue:string, user:string) => {
    setMessageItems(prevMessageItems => {
      const updatedArray = lodash.cloneDeep(prevMessageItems);
      updatedArray.push({ "type": user, "message": messageValue, "time": "", "message_id": '' });
      return updatedArray;
    });
    setTimeout(() => {
      const containers = document.querySelectorAll('.message-content');
      containers.forEach(container => {
        container.scrollTop = container.scrollHeight;
      });
      if (audioRef.current) {
        audioRef.current.play();
      }
    }, 200);
  }

  const sendMessage = () => {
    if (messageLoading == true) return;
    if (messageValue.trim() === "") return;
    const messageId = uuid();
    const updatedArray = [...messageItems];
    const messageContent = {"type":'me',"message":messageValue,"time":"", "message_id": messageId, 'fd': currentFd.current}
    setMessageItems([...updatedArray, messageContent])
    setMessageLoading(true);
    setTimeout(() => {
      const containers = document.querySelectorAll('.message-content');
      containers.forEach(container => {
        container.scrollTop = container.scrollHeight;
      });
      setMessageValue('');
      sendMessageApi(messageContent).then((response) => {
        if (audioRef.current) {
          audioRef.current.play();
        }
        setMessageLoading(false);
      }).catch((error) => {
        setMessageLoading(false);
      });


    }, 200);
  }

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
      event.preventDefault();
      sendMessage();
    }
  }, [messageValue]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const messageContentDiv = messageContentRef.current;
    if (messageContentDiv) {
      // 监听滚动事件
      const handleScroll = () => {
        if (messageContentDiv.scrollTop === 0) {
          getMessage(); 
        }
      };
      messageContentDiv.addEventListener('scroll', handleScroll);
      return () => {
        messageContentDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);


  return (
    <div css={messageBox}>
      <audio ref={audioRef} style={{display:'none'}} src="/notification.mp3" />
      <div className='chat-button' onClick={openChat}>
        <ChatBubbleLeftEllipsisIcon width={'25px'} color={'#fff'} />
      </div>
      <div css={messagePop} style={{ display: chatVisible ? 'flex' : 'none' }}>
        <div className='message-header'>
          <div>

          {connectionStatus == '0' ? (
            <div  style={{textAlign:'center',marginTop:'3px'}}>
              <small> <Loading3QuartersOutlined size={8} spin /> Connecting...</small>
            </div>
          ) : (
            <span> <Badge status="processing" color="#67C23A"/> &nbsp;AeroHub Chats</span>
          )}

            
          
          </div>
          <div onClick={()=>(setChatVisible(false))} style={{cursor: 'pointer'}}><XMarkIcon  width={'18px'} color={'#ffffff'} /></div>
        </div>
        <div className='message-content' ref={messageContentRef} >
          {moreLoading== true && (
            <div  style={{textAlign:'center',marginTop:'3px'}}>
              <small> <Loading3QuartersOutlined size={8} spin /> Fetching historical chat conversations...</small>
            </div>
          )}
          {messageItems.map((item) => (
            <>
            {item.type == 'aerohub' ? (
              <div className='aerohub'>
                <Avatar size={30} src="/logo-300.png" />
                <div className='message'>{item.message}</div>
              </div>
            ) : (
              <div className='me'>
                <div className='message'>{item.message}</div>
              </div>
            )}
            </>
          ))}
          {messageLoading == true && (
            <div  style={{textAlign:'right',marginTop:'3px'}}><small>sending...</small></div>
          )}
        </div>
        <div className='message-bottom'>
          <TextArea
            value={messageValue}
            style={{fontSize: '14px'}}
            onChange={e => setMessageValue(e.target.value)}
            placeholder="Ask a question..."
            autoSize={{ minRows: 1, maxRows: 3 }}
          />
          <div style={{lineHeight: '0px',cursor: 'pointer'}} onClick={sendMessage}><PaperAirplaneIcon width={'18px'} color={'#313131'} /></div>
        </div>
      </div>
    </div>
  )
}

export default MessageService

const messageBox = css`
    .chat-button{
      position: fixed;
      bottom: 50px;
      right: 50px;
      background: #3b5cff;
      width: 50px;
      height: 50px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 100px;
      cursor: pointer;
    }
    .chat-button:hover{
      background: #2146f6;
    }
`
const messagePop = css`
    width: 400px;
    height: 560px;
    background: #FFF;
    border-radius: 10px;
    position: fixed;
    bottom: 115px;
    right: 50px;
    z-index: 99;
    flex-direction: column;
    flex-wrap: nowrap;
    box-shadow: 0 3px 8px 0 rgba(0, 72, 129, 0.1);
    .message-header{
      background:#3b5cff;
      padding: 10px 20px;
      color: #FFF;
      border-radius: 10px 10px 0px 0px;
      text-align: left;
      font-size: 15px;
      font-weight: 600;
      box-shadow: 1px 4px 8px 0 rgb(0 0 0 / 10%);
      display: flex;
      justify-content: space-between;
    }
    .message-content{
      padding: 20px;
      flex: 1;
      overflow-y: auto;
    }

    .aerohub{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-top:15px;
      gap:8px;
    }
    .me{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top:15px;
    }
    .aerohub .message{
      max-width: 250px;
      min-width: 50px;
      border-radius: 20px;
      padding: 10px;
      overflow-wrap: break-word;
      background: #F0F0F0;
      border-bottom-left-radius: 0px;
      text-align: left;
      line-height: 20px;
    }
    .me .message{
      max-width: 250px;
      min-width: 50px;
      border-radius: 20px;
      padding: 10px;
      overflow-wrap: break-word;
      background: #3b5cff;
      border-bottom-right-radius: 0px;
      text-align: left;
      color:#FFF;
      line-height: 20px;
      box-shadow: 0 3px 8px 0 rgba(0, 72, 129, 0.1);
      
    }

    .message-bottom{
      display: flex;
      padding: 10px 20px 10px 20px;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      // border-top: 0.01rem solid #c6cfff;
    }
`
