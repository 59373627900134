import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const AirlinePage = lazy(() => import('@/pages/airline'))

const BalanceRoute = [
  {
    path: '/airline',
    title: 'Airline',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <AirlinePage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
] as RouteObject[]

export default BalanceRoute
