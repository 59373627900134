import HttpRequestV2 from '@/utils/requestService'

export const downloadTaskList = (params: any) => {
  return HttpRequestV2.get({
    url: `/client/common/task/items`,
    params,
  })
}

export const downloadTaskCreate = (data: any): Promise<any> => {
  return HttpRequestV2.post({
    url: `/client/common/task/create`,
    data,
  })
}

