import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import React from 'react'
import { RootState } from '../../index'

export interface MenuItem {
  id: string
  label: string
  path: string
  icon?: string
  children?: Array<MenuItem>
  meta?: {
    hideInMenu?: boolean
    icon?: React.ReactNode
    authority?: boolean
  }
}

export type Menus = MenuItem[]

export interface MenuState {
  value: Menus
}

export const fetchMenu = createAsyncThunk('menu/fetchMenu', async () => {
  await new Promise((resolve) =>
    setTimeout(() => {
      resolve(null)
    }, 100)
  )
  return [
    {
      id: '1',
      label: '首页',
      path: 'home',
    },
    {
      id: '2',
      label: '用户信息',
      path: 'user',
      meta: {
        authority: true,
      },
    },
    {
      label: '产品展示',
      id: '3',
      path: 'product',
      children: [
        {
          id: '4',
          label: '产品列表',
          path: 'list',
          meta: {
            authority: true,
          },
        },
        {
          id: '5',
          label: '产品详情',
          path: 'detail',
          meta: {
            hideInMenu: true,
          },
        },
      ],
    },
    {
      id: '6',
      label: '权限',
      path: 'permission',
      meta: {
        authority: true,
      },
    },
  ] as Menus
})

const initialState = {
  value: [],
} as MenuState

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      // Add user to the state array
      state.value = action.payload
    })
  },
})

export const selectMenu = (state: RootState) => state.menu.value

export default menuSlice.reducer
