import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const AncillarysPage = lazy(() => import('@/pages/ancillarys'))
const AncillaryBookingPage = lazy(() => import('@/pages/ancillary-booking'))
const AncillaryDetailPage = lazy(() => import('@/pages/ancillary-detail'))

const ChangeRoute = [
  {
    path: '/ancillarys',
    title: 'Ancillarys',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <AncillarysPage />
        </BaseLayout>
      </Suspense>
    ),
  },
  {
    path: '/ancillary-booking/:orderNum',
    title: 'Ancillary-booking',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <AncillaryBookingPage />
        </BaseLayout>
      </Suspense>
    ),
  },
  {
    path: '/ancillary-detail/:orderNum',
    title: 'Ancillary detail',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <AncillaryDetailPage />
        </BaseLayout>
      </Suspense>
    ),
  },
] as RouteObject[]

export default ChangeRoute
