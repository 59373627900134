import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const FlightPage = lazy(() => import('@/pages/flight'))
const FlightDetailPage = lazy(() => import('@/pages/flight-detail'))
const FlightSeatsPage = lazy(() => import('@/pages/flight-seats'))


const FlightRoute = [
  {
    path: '/flight',
    title: 'Flight',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <FlightPage />
        </BaseLayout>
      </Suspense>
    ),
  },
  {
    path: '/flight-detail',
    title: 'Flight detail',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <FlightDetailPage />
        </BaseLayout>
      </Suspense>
    ),
  },
 {
    path: '/flight-seats',
    title: 'Flight seats',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <FlightSeatsPage />
        </BaseLayout>
      </Suspense>
    ),
  },
] as RouteObject[]

export default FlightRoute
