import { BLUE_COLOR, LIGHT_BLUE_COLOR } from '@/constant/style'
import styled from '@emotion/styled'
import { Button } from 'antd'

export const PrimaryButton = styled(Button)`
    //border-radius: 4px!important;
  background: linear-gradient(
    150deg,
    ${LIGHT_BLUE_COLOR} 0%,
    ${BLUE_COLOR} 100%
  ) !important;
    border: none!important;
  &:disabled {
    color: #fff !important;
  }
`
export const WarningButton = styled(Button)`
    //border-radius: 4px!important;
    background: linear-gradient(135deg, #f49800 0%, #ee7500 100%) !important;
    border: none!important;
`
export const DefaultButton = styled(Button)`
    //border-radius: 4px!important;
    background: linear-gradient(150deg, #fcfdfd 0%, #f3f3f3 100%) !important;
    //border: none!important;
`
export const LightButton = styled(Button)`
    background: linear-gradient(150deg, #fff 0%, #fff 100%) !important;
    color:#0a2b5c;
`