import HttpRequest from '@/utils/request'
import type { LoginRequestParams, LoginResult, UserInfo, ssoTokenRequestParams } from './type'
import HttpRequestV2 from '@/utils/requestV2'

export const LetsflyHubAPItestToken = (): Promise<UserInfo> => {
  return HttpRequest.get({
    url: `/client/letsflyhub/fastlogin`,
  })
}
export const ssoToken = (data: ssoTokenRequestParams): Promise<LoginResult> => {
  return HttpRequest.post({
    url: `/client/saml/token`,
    data,
  })
}

export const logOut = () => {
  return HttpRequest.put({
    url: `/client/user/logout`,
  })
}

export const getUserInfo = (): Promise<any> => {
  return HttpRequestV2.get({
    url: `/client/account/info`,
  })
}

export const getList = (data: LoginRequestParams) => {
  return HttpRequest.get({
    url: `/client/flight/place?word=hkt`,
    data,
  })
}

export const getSettingInfo = () => {
  return HttpRequest.get({
    url: `/client/user/setting-info`,
  })
}

export const setSettingInfo = (data: {cus_address: string; operate_email: string; engine_email: string }) => {
  return HttpRequest.post({
    url: `/client/user/setting-info`,
    data,
  })
}

// 修改账户密码
export const changePassword = (data: any): Promise<any> => {
  return HttpRequestV2.post({
    url: `/client/account/change-password`,
    data,
  })
}

export const login = (data: LoginRequestParams): Promise<LoginResult> => {
  return HttpRequestV2.post({
    url: `/client/authentication/login`,
    data,
  })
}

export const adminQuickLogin = (data: any): Promise<LoginResult> => {
  return HttpRequestV2.post({
    url: `/client/authentication/admin-quick-login`,
    data,
  })
}

// 发送邮箱验证码
export const emailCodeSend = (data: any): Promise<any> => {
  return HttpRequestV2.post({
    url: `/client/authentication/email-code-send`,
    data,
  })
}

// 发送邮箱验证码
export const emailCodeLogin = (data: any): Promise<any> => {
  return HttpRequestV2.post({
    url: `/client/authentication/email-code-login`,
    data,
  })
}