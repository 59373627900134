import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const OrderDetailPage = lazy(() => import('@/pages/order-detail'))

const FlightRoute = [
  {
    path: '/order-detail/:sessionId',
    title: 'Order detail',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <OrderDetailPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
] as RouteObject[]

export default FlightRoute
