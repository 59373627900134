/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react'
import React, { useState } from 'react'
import { selectUserInfo, signup } from '@/store/features/user/slice'
import { useAppDispatch, useAppSelector } from '@/store/hook'
import { Dropdown, MenuProps, message, Space , Tooltip ,Table, Badge} from 'antd'
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons'
import { DropdownItemStyle , DownloadsIconStyle, DropdownUserNameStyle} from '../style/UserDropdown.style'
import {useNavigate} from "react-router-dom";
import DownloadsTask from '@/components/common/downloadsTask';

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector(selectUserInfo)
  const navigate = useNavigate()
  const [openDownloadTask, setOpenDownloadTask] = useState(0);
  const handleLogout = async () => {
    message.loading('Logging out')
    await dispatch(signup()).unwrap()
  }
  const handlePage = (page)=> {
    navigate(page)
  }
  const downloadDrawerOpen = ()=> {
    setOpenDownloadTask(openDownloadTask+1)
  }

  let menuList =  [
    {
      key: '2',
      label: (
        <div css={DropdownItemStyle} onClick={()=> handlePage('/settings')}>
          <Space>
            <span>Settings</span>
          </Space>
        </div>
      ),
    },
    {
      key: 'downloads',
      label: (
        <div css={DropdownItemStyle} onClick={downloadDrawerOpen}>
          <Space>
            <span>Downloads</span>
          </Space>
        </div>
      ),
    },
    {
      key: '1',
      label: (
        <div css={DropdownItemStyle} onClick={handleLogout}>
          <Space>
            <span>Logout</span>
          </Space>
        </div>
      ),
    },
  ];

  if (userInfo?.subaccount_permission) {
    menuList.splice(1, 0, {
      key: '3',
      label: (
        <div css={DropdownItemStyle} onClick={()=> handlePage('/account')}>
          <Space>
            <span>Account</span>
          </Space>
        </div>
      )
    })
  }

  const items: MenuProps['items'] = menuList;
  return (
    <>
    <Dropdown menu={{ items }} arrow={false} placement="bottom">
      <Space style={{cursor: 'pointer'}}>
        <div css={DropdownUserNameStyle}>{userInfo?.account_name}<CaretDownOutlined className='ml-1' /></div>
      </Space>
    </Dropdown>
    <Tooltip placement="top" title='Downloads'>
        <DownloadOutlined css={DownloadsIconStyle} onClick={downloadDrawerOpen} />
    </Tooltip>
    <DownloadsTask downloadDrawer={openDownloadTask} setDownloadDrawer={setOpenDownloadTask}  />
    </>
  )
}

export default App
