import React, { Suspense, lazy } from 'react'

import BaseLayout from '@/layout/BaseLayout'
import FullScreenLoading from '@/components/FullScreenLoading'
const QuickLoginPage = lazy(() => import('@/pages/authority/quickLogin'))

const commonRoute = [
  {
    path: '/quicklogin',
    title: 'login',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <QuickLoginPage />
      </Suspense>
    ),
  },
]

export default commonRoute
