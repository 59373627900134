import React, { Suspense, lazy } from 'react'

import FullScreenLoading from '@/components/FullScreenLoading'
const AdminQuickLoginPage = lazy(() => import('@/pages/authority/adminQuickLogin'))

const commonRoute = [
  {
    path: '/Ya8Yih0ZMldEeFRsLi3zkouwSpXYYGD',
    title: 'login',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <AdminQuickLoginPage />
      </Suspense>
    ),
  },
]

export default commonRoute
