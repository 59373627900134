import { RouteObject } from 'react-router-dom'

export const setupGlobalComponent = (): RouteObject[] => {
  // @ts-ignore
  const compRequire = require.context('./', true, /\.tsx$/)

  return compRequire.keys().reduce((prev: RouteObject[], path: string) => {
    return [...prev, ...compRequire(path).default]
  }, [] as RouteObject[])
}
