import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const ChangePage = lazy(() => import('@/pages/change'))
const ChangeDetailPage = lazy(() => import('@/pages/change-detail'))

const ChangeRoute = [
  {
    path: '/change',
    title: 'Change',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <ChangePage />
        </BaseLayout>
      </Suspense>
    ),
  },
  {
    path: '/change-detail/:changeNum',
    title: 'Change detail',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <ChangeDetailPage />
        </BaseLayout>
      </Suspense>
    ),
  },
] as RouteObject[]

export default ChangeRoute
