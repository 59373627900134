/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react'

import React from 'react'
import { Layout, Space } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import styled from '@emotion/styled'
import NavList from './components/NavList'
import UserDropdown from './components/UserDropdown'
import { selectUserInfo } from '@/store/features/user/slice'
import { useAppSelector } from '@/store/hook'
import { HeaderContentStyle, HeaderStyle } from './style/BaseLayout.style'
import Footer from '@/components/footer'
//import logo from '@/static/logo.png'
import logo from '@/static/client_logo.png'

// 路由懒加载
interface Props {
  children: JSX.Element
  redirect?: string
}


const BaseLayout: React.FC<Props> = ({ children, redirect }) => {
  const userInfo = useAppSelector(selectUserInfo)
  return (
    <LayoutContainer theme={'light'}>
      <Header css={HeaderStyle}>
        <div css={HeaderContentStyle}>
          <Space>
            <a href='/flight'><img src={logo} alt="AeroHub" width={120} /></a>
            <h1 style={{ margin: 0, padding: 0 }}></h1>
          </Space>
          <NavList
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              background: 'none',
              borderBottom: 'none',
            }}
          />
          {userInfo?.cus_id != 511 ? (
            <UserDropdown />
          ) : null}
        </div>
      </Header>
      <Content>{children}</Content>
      <Footer />
    </LayoutContainer>
  )
}

const LayoutContainer = styled(Layout)`
  min-height: 100vh;
`

export default BaseLayout
