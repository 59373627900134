import React from 'react'
import { createRoot } from 'react-dom/client'
import './styles/index.less'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import 'antd/dist/reset.css'
import { store } from '@/store'
import { Provider } from 'react-redux'
import RouteBeforeEach from './components/RouteBeforeEach'
import { FONT_COLOR, PRIMARY_COLOR } from './constant/style'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <ConfigProvider
    locale={enUS}
    theme={{
      token: {
        colorPrimary: PRIMARY_COLOR,
        borderRadius: 4,
        colorTextBase: FONT_COLOR,
      },
      components: {
        Radio: {
          colorPrimary: FONT_COLOR,
        },
        Checkbox: {
          colorPrimary: FONT_COLOR,
        },
        Card: {
          borderRadius: 10,
        },
      },
    }}
    componentSize="large"
  >
    <BrowserRouter>
      <Provider store={store}>
        <RouteBeforeEach>
          <App />
        </RouteBeforeEach>
      </Provider>
    </BrowserRouter>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
