import styled from '@emotion/styled'
import { Spin } from 'antd'

interface Props {
  children?: JSX.Element
  loading?: boolean
  tip?: string
}

const FullScreenLoading: React.FC<Props> = ({
  children,
  loading = true,
  tip = 'Loading...',
}) => {
  return (
    <>
      {loading ? (
        <FullScreenLoadingContainer>
          <Spin size="large" tip={tip} />
        </FullScreenLoadingContainer>
      ) : null}
      {children}
    </>
  )
}

const FullScreenLoadingContainer = styled.div({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'rgba(255,255,255,1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99999,
})

export default FullScreenLoading
