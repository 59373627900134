import { createSlice } from '@reduxjs/toolkit'
import { useState } from "react";
import { FlightItemType } from "@/services/flight/type";
import { Console } from 'console';

interface FlightState {
    isFlightHome?: boolean,
    flightData?: FlightItemType[],
    carriers: string[],
    checkedCarriers: string[],
    checkedChannel: number[],
    originData: any[]
    bundles: any[]
}

const initialState = {
    isFlightHome: true,
    flightData: [],
    loadMoreData: [],
    carriers: [],
    checkedCarriers: [],
    checkedChannel: [],
    originData: [],
    bundles: []
} as FlightState

export const flightSlice = createSlice({
    name: 'flight',
    initialState,
    reducers: {
        changeIsFlightHome: (state, action) => {
            state.isFlightHome = action.payload;
        },
        changeFlightData: (state, action) => {
            state.flightData = action.payload;
        },
        changeLoadMoreData: (state, action) => {
            state.loadMoreData = action.payload;
        },
        changeCarriers: (state, action) => {
            state.carriers = action.payload;
        },
        changeCheckedCarriers: (state, action) => {
            state.checkedCarriers = action.payload;
        },
        changeCheckedChannel: (state, action) => {
            state.checkedChannel = action.payload;
        },
        changeOriginData: (state, action) => {
            state.originData = action.payload;
        },
        changeBundles: (state, action) => {
            state.bundles = action.payload;
        },
    }
})
// 每个 case reducer 函数会生成对应的 Action creators
export const {
    changeIsFlightHome,
    changeFlightData,
    changeLoadMoreData,
    changeOriginData,
    changeCarriers,
    changeCheckedCarriers,
    changeCheckedChannel,
    changeBundles
} = flightSlice.actions;

export default flightSlice.reducer
