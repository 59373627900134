/** @jsxImportSource @emotion/react */
import { FOOTER_HEIGHT, PAGE_MAX_WIDTH } from '@/constant/style'
import { css, jsx } from '@emotion/react'
import MessageService from '@/components/common/messageService';
import { selectUserInfo } from '@/store/features/user/slice'
import { useSelector } from "react-redux";

const Footer: React.FC = () => {
  const userInfo = useSelector(selectUserInfo);
  return (
    <>
    <div css={FooterStyle}>
      <div className='footer-agreement'>
        <a target='_blank' href='https://www.aerohubtech.com/privacy-policy'>Privacy policy</a> | <a target='_blank' href='https://www.aerohubtech.com/general-conditions'>General conditions</a> | <a target='_blank' href='https://www.aerohubtech.com/contact'>
          Contact us</a>
      </div>
      <div className="footer-content">
        2014 - 2023 © AeroHub, all rights reserved.
      </div>
    </div>
    {userInfo?.is_chat == true && (
      <MessageService  />
    )}
    </>
  )
}

export default Footer

const FooterStyle = css`
  line-height: ${FOOTER_HEIGHT}px;
  text-align: center;
  .footer-content {
    margin: 0 auto 40px;
    font-size: 13px;
    color: #9e9e9e;
  }
  .footer-agreement {
    font-size: 13px;
  }
`
