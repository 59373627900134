import { HEADER_MAX_WIDTH } from '@/constant/style'
import { css } from '@emotion/react'

export const HeaderStyle = css`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  /* background: none !important; */
  background-color: #fff !important;
  box-shadow: 0 3px 8px 0 rgb(0 72 129 / 10%);
  /* background-color: rgba(240, 242, 245, 0.4) !important; */
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  /* &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(240, 242, 245, 0.9);
    filter: blur(8px);
    z-index: 1;
  } */
`

export const HeaderContentStyle = css`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: ${HEADER_MAX_WIDTH}%;
  margin: 0 auto;
`
