import { message } from 'antd';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// 创建一个内联的Web Worker
function createWorker() {
  const blob = new Blob([`
    importScripts('https://cdn.letsflytech.com/asset/libs/exceljs.min.js');
    importScripts('https://cdn.letsflytech.com/asset/libs/FileSaver.min.js');

    self.onmessage = async function(e) {
      const { jsonData, fileTitle, lastColor } = e.data;

      if (jsonData.length === 0) {
        self.postMessage({ type: 'error', message: '没有数据可以导出' });
        return;
      }

      try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Booking_Report');
        worksheet.columns = Object.keys(jsonData[0]).map(key => ({ header: key, key: key }));

        jsonData.forEach(data => {
          worksheet.addRow(data);
        });

        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'dae1f3' },
          };
        });

        if (lastColor != undefined) {
          const lastRow = worksheet.getRow(jsonData.length + 1);
          lastRow.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: lastColor },
            };
          });
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        self.postMessage({ type: 'success', blob: blob, fileTitle: fileTitle });
      } catch (error) {
        self.postMessage({ type: 'error', message: error.message });
      }
    };
  `], { type: 'application/javascript' });

  return new Worker(URL.createObjectURL(blob));
}

export function jsonToExcel(jsonData :any, fileTitle:any, lastColor:any) {
  if (jsonData.length === 0) {
    return message.error('No data available for export.');
  }
  const hide = message.loading('Creating Excel file...', 15000);
  const worker = createWorker();
  worker.postMessage({ jsonData, fileTitle, lastColor });
  worker.onmessage = function(e) {
    const { type, message: msg, blob, fileTitle } = e.data;
    hide();
    if (type === 'error') {
      message.error(msg);
    } else if (type === 'success') {
      saveAs(blob, `${fileTitle}.xlsx`);
      message.success('Download successful');
    }
  };

  worker.onerror = function(e) {
    hide()
    message.error('An error occurred while processing the Excel file');
  };
}

// 解压压缩后的json数据
export function decodeAndDecompress(encodedData: any) {
  const decodedBase64 = atob(encodedData);
  const uint8Array = new Uint8Array(decodedBase64.length);
  for (let i = 0; i < decodedBase64.length; i++) {
    uint8Array[i] = decodedBase64.charCodeAt(i);
  }
  const pako = require('pako');
  const inflatedData = pako.inflate(uint8Array, { to: 'string' });
  return JSON.parse(inflatedData);
}

// 获取当前时间(年月日时分秒)
export function YmdHis() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  const hours = today.getHours().toString().padStart(2, '0');
  const minutes = today.getMinutes().toString().padStart(2, '0');
  const seconds = today.getSeconds().toString().padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

// 获取当前日期(年月日)
export function Ymd() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
}