import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const SettingsPage = lazy(() => import('@/pages/settings'))

const SettingsRoute = [
  {
    path: '/settings',
    title: 'Settings',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <SettingsPage />
        </BaseLayout>
      </Suspense>
    ),
    index: true,
  },
] as RouteObject[]

export default SettingsRoute;
