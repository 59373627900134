// 间距大小
export const MARGIN = 24
export const HALF_MARGIN = 12

// 圆角大小
export const RADIU_SIZE = 8

export const LARGE_RADIU_SIZE = 12

// 页面最大宽度
export const PAGE_MAX_WIDTH = 80

export const HEADER_MAX_WIDTH = 85

// header 高度
export const HEADER_HEIGHT = 64

// footer高度
export const FOOTER_HEIGHT = 32

// 颜色
export const PRIMARY_COLOR = '#1858f0'
export const FONT_COLOR = '#0a2b5c'
export const BODY_COLOR = '#4d6782'
export const LIGHT_FONT_COLOR = '#9db2d2'
// export const PRIMARY_COLOR = '#1890ff'
export const AMOUNT_COLOR = '#ee7500'

export const WARNING_COLOR = '#e94944'

export const LIGHT_ORIGIN_COLOR = '#F49800'
export const ORIGIN_COLOR = '#ee7500'
export const ORIGIN_GROUND = [LIGHT_ORIGIN_COLOR, ORIGIN_COLOR]
export const LIGHT_BLUE_COLOR = '#6ba1ff'
export const MEDIUM_BLUE_COLOR = '#0d9eff'
export const BLUE_COLOR = '#1858f0'
export const BLUT_GROUND = [LIGHT_BLUE_COLOR, MEDIUM_BLUE_COLOR, BLUE_COLOR]
export const FONT_A7A7A7 = '#A7A7A7'
export const BORDER_COLOR = '#DBE0E7'
