import React, { Suspense, lazy } from 'react'

import BaseLayout from '@/layout/BaseLayout'
import FullScreenLoading from '@/components/FullScreenLoading'
const LoginPage = lazy(() => import('@/pages/authority/login'))

const commonRoute = [
  {
    path: '/login',
    title: 'Login',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <LoginPage />
      </Suspense>
    ),
  },
]

export default commonRoute
