import FullScreenLoading from '@/components/FullScreenLoading'
import BaseLayout from '@/layout/BaseLayout'
import { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const RefundPage = lazy(() => import('@/pages/refund'))
const RefundDetailPage = lazy(() => import('@/pages/refund-detail'))

const RefunRoute = [
  {
    path: '/refund',
    title: 'Refund',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <RefundPage />
        </BaseLayout>
      </Suspense>
    ),
  },
  {
    path: '/refund-detail/:refundNum',
    title: 'Refund detail',
    element: (
      <Suspense fallback={<FullScreenLoading />}>
        <BaseLayout>
          <RefundDetailPage />
        </BaseLayout>
      </Suspense>
    ),
  },
] as RouteObject[]

export default RefunRoute
