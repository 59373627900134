import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  to: string
}
const Redirect: React.FC<Props> = ({ to }) => {
  let navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}

export default Redirect
